import axios from 'axios'
import axiosInstance from "../helpers/axiosInstance";
import { TRANSACTION_STATUS_SUCCESS, validateTransactionStatus } from './concurrencyService';

const ACTIVE_REPLENISHMENT_STATUSES = ['IN_TRANSIT', 'REQUESTED', 'PROCESSING', 'COMPLETED', 'IN_PUTAWAY', 'WITHOUT_SCHEDULING', 'RECEIVED']

export const fetchReplenishments = async (params) => {
    const res = await axiosInstance.get(`${process.env.REACT_APP_PLATFORM_URL}/api/v1/replenishments_wms`, { params: params })
    return res.data
}

export const fetchReplenishment = async (id) => {
    const res = await axiosInstance.get(`${process.env.REACT_APP_PLATFORM_URL}/api/v1/replenishments/`+id)
    return res.data
}

export const fetchReplenishmentPaginated = async (id, params = {}) => {
    const res = await axiosInstance.get(`${process.env.REACT_APP_PLATFORM_URL}/api/v1/replenishments_paginated/`+id, {params: params})
    return res.data
}

export const fetchFreeLocationsForReplenishment = async (params) => {
    const res = await axiosInstance.get(`${process.env.REACT_APP_PLATFORM_URL}/api/v1/fulfillment-tasks/empty-reception-locations`, { params: params })
    return res.data
}

// DEPRECATED Use updateReplenishmentStatus instead.
export const assignLocationToReplenishment = async (location_id, replenishment_id) => {
    
    let data = {
	    "warehouse_location_id": location_id,
		"replenishment_id": replenishment_id
    }

    const res = await axiosInstance.post(`${process.env.REACT_APP_PLATFORM_URL}/api/v1/fulfillment-tasks/assign-location-to-replenishment`, data)
    return res.data
}

export const searchProductInReplenishment = async (query, replenishment_id) => {
    // fulfillment-tasks/search-replenishment-product
    const params = {query: query, all_lots: true, is_kit: false, replenishment_id: replenishment_id}
    const res = await axiosInstance.get(`${process.env.REACT_APP_PLATFORM_URL}/api/v1/fulfillment-tasks/search-replenishment-product`, {params: params})
    return res.data
}

export const receiveProduct = async (product, insertQuantity, warehouse_location_id, replenishment_id, user_id) => {
    
    let data = {
	    "warehouse_location_id": warehouse_location_id,
		"replenishment_id": replenishment_id,
		"product_id": product.id,
        "lot_id": product.lot?.id || 0,
	    "quantity": insertQuantity,
        "receiver_id": user_id
    }
    const res = await axiosInstance.post(`${process.env.REACT_APP_PLATFORM_URL}/api/v1/fulfillment-tasks/receive`, data)
    return res.data
}

export const correctProduct = async (product, newQuantity, replenishment_id, lot_id = null) => {
    
    let data = {
		"replenishment_id": replenishment_id,
		"product_id": product.id,
        "lot_id": lot_id,
	    "new_reception_quantity": newQuantity
    }

    const res = await axiosInstance.post(`${process.env.REACT_APP_PLATFORM_URL}/api/v1/fulfillment-tasks/correct-reception`, data)
    return res.data
}

export const damagedProduct = async (product, insertQuantity, replenishment_id, lot_id = null) => {
    
    let data = {
		"replenishment_id": replenishment_id,
		"product_id": product.id,
	    "quantity": insertQuantity,
        "lot_id": lot_id
    }

    const res = await axiosInstance.post(`${process.env.REACT_APP_PLATFORM_URL}/api/v1/fulfillment-tasks/mark-damaged`, data)
    return res.data
}

export const addProductToReplenishment = async (product, replenishment_id) => {
    
    let data = {
		"replenishment_id": replenishment_id,
		"product_id": product.id
    }

    const res = await axiosInstance.post(`${process.env.REACT_APP_PLATFORM_URL}/api/v1/fulfillment-tasks/add-product-to-replenishment`, data)
    return res.data
}

// DEPRECATED Use receiveReplenishment or updateReplenishmentStatus instead.
export const updateReplenishment = async (replenishment_id, status, number_of_boxes = 0) => {
    
    let data = {
		"replenishment_id": replenishment_id,
		"status": status,
        "number_of_boxes": number_of_boxes
    }

    const res = await axiosInstance.post(`${process.env.REACT_APP_PLATFORM_URL}/api/v1/fulfillment-tasks/update-replenishment`, data)
    return res.data
}

export const updateReplenishmentLots = async (replenishment_id, lots) => {
    
    let data = {
		"replenishment_id": replenishment_id,
		"lots": lots
    }

    const res = await axiosInstance.post(`${process.env.REACT_APP_PLATFORM_URL}/api/v1/fulfillment-tasks/update-replenishment-lots`, data)
    return res.data
}

export const receiveReplenishment = async (replenishment_id, status, number_of_boxes = 0) => {
    const data = {
		replenishment_id,
		status,
        number_of_boxes
    }

    const res = await axiosInstance.post(`${process.env.REACT_APP_PLATFORM_URL}/api/v1/replenishments/received`, data)

    return res.data
}

export const updateQualityCheck = async (replenishment_id, qualityCheckData) => {
    const data = {
        quality_check_data: {
            attended_appointment: qualityCheckData.clientAttendedAppointment,
            segregated_product: qualityCheckData.productIsSegregated,
            labeled_product: qualityCheckData.productIsLabeled
        }
    }

    const res = await axiosInstance.patch(`${process.env.REACT_APP_PLATFORM_URL}/api/v1/replenishments/${replenishment_id}/quality-check`, data)

    return res.data
}

export const updateReplenishmentStatus = async (replenishment_id, status) => {

    const data = {
		"status": status
    }

    const res = await axiosInstance.patch(`${process.env.REACT_APP_PLATFORM_URL}/api/v1/replenishments/${replenishment_id}/status`, data)

    return res.data
}

export const completeReplenishment = async (replenishment_id) => {
    try {
        let data = {
            "replenishment_id": replenishment_id
        }
        const res = await axiosInstance.post(`${process.env.REACT_APP_PLATFORM_URL}/api/v1/fulfillment-tasks/complete-replenishment`, data)
        if (res.status === 202 && res.data.transaction_id) {
            const transactionStatus = await validateTransactionStatus(res.data.transaction_id)
            console.log("transactionStatus: ", transactionStatus)
            if (transactionStatus != TRANSACTION_STATUS_SUCCESS) {
                throw new Error('Error')
            }
        }
        return res.data
    } catch (error) {
        throw error
    }
    
}

export const getReplenishmentPackageProduct = async (scannedBarcode, replenishmentId) => {
    const params = {replenishment_id: replenishmentId, scanned_barcode: scannedBarcode}
    const res = await axiosInstance.get(`${process.env.REACT_APP_PLATFORM_URL}/api/v1/fulfillment-tasks/get-replenishment-package-product`, {params: params})
    return res.data
}

export const getPackageProductQuantity = async (scannedBarcode, replenishmentId) => {
    const params = {replenishment_id: replenishmentId, scanned_barcode: scannedBarcode}
    const res = await axiosInstance.get(`${process.env.REACT_APP_PLATFORM_URL}/api/v1/fulfillment-tasks/get-package-product-quantity`, {params: params})
    return res.data
}
