import { Fragment, useState, useRef } from "react"
import { Dialog, Transition } from "@headlessui/react"
import { XIcon } from "@heroicons/react/solid"
import { useTranslation } from 'react-i18next'

import { Button, SearchInput } from "../../components"
import { getPackageProductQuantity } from "../../services"

export const ReplenishmentPackageQuantityConfirmDialog = ({open, replenishment, replenishmentPackageProduct, onConfirm, onCancel}) => {
    const [showQuantityInput, setShowQuantityInput] = useState(false)
    const [inputQuantity, setInputQuantity] = useState("")
    const [inputQuantityError, setInputQuantityError] = useState(false)
    const searchInputRef = useRef(null)
    const { i18n } = useTranslation()

    const handleInputQuantityChange = async(scannedBarcode) => {
        if(scannedBarcode === ""){
            return
        }

        searchInputRef.current?.resetInput()
        const packageProductQuantity = await getPackageProductQuantity(scannedBarcode, replenishment.id)

        if(!packageProductQuantity?.product_quantity){
            setInputQuantityError(true)
            return
        }

        setShowQuantityInput(false)
        setInputQuantityError(false)
        onConfirm(replenishmentPackageProduct, packageProductQuantity.product_quantity)
    }

    return (
        <Transition show={open} as={Fragment}>
            <Dialog
                as="div"
                className="fixed z-10 inset-0 overflow-y-auto"
                onClose={() => {}}
            >
                <div className="flex items-center justify-center min-h-screen">
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                    </Transition.Child>

                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        enterTo="opacity-100 translate-y-0 sm:scale-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                        leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    >
                        <div className="relative bg-white rounded-lg shadow-xl sm:max-w-5xl sm:w-full p-6 text-gray-600 my-3 overflow-hidden max-h-[length:90vh]">
                            <div className="absolute top-0 right-0 p-5 cursor-pointer" onClick={() => onCancel()}>
                                <XIcon className="h-5 w-5"/>
                            </div>

                            {showQuantityInput ? (
                                <>
                                    <Dialog.Title className="text-center text-xl" >
                                        {i18n.t("replenishment.scan_product_quantity_barcode")}
                                    </Dialog.Title>

                                    <Dialog.Description className="w-full" as="div">
                                        <SearchInput
                                            debounce="10"
                                            inputValue={inputQuantity}
                                            className="w-full my-2"
                                            onChange={handleInputQuantityChange}
                                            onReset={() => setInputQuantity(null)}
                                            ref={searchInputRef}
                                        />

                                        {inputQuantityError && (
                                            <div className="text-red-600">
                                                {i18n.t("replenishment.scan_product_quantity_barcode_error")}
                                            </div>
                                        )}
                                    </Dialog.Description>
                                </>
                            ) : (
                                <>
                                    <Dialog.Title className="text-center text-xl" >
                                        {i18n.t("replenishment.question_package_complete")}
                                    </Dialog.Title>

                                    <Dialog.Description className="text-center">
                                        {replenishmentPackageProduct?.product_package?.expected_product_quantity === 1 ? (
                                            i18n.t("replenishment.product_quantity_one", {
                                                product_quantity: replenishmentPackageProduct?.product_package?.expected_product_quantity
                                            })
                                        ) : (
                                            i18n.t("replenishment.product_quantity_multiple", {
                                                product_quantity: replenishmentPackageProduct?.product_package?.expected_product_quantity
                                            })
                                        )}
                                    </Dialog.Description>

                                    <div className="flex justify-center gap-5">
                                        <Button
                                            type="primary"
                                            className=""
                                            onClick={() => onConfirm(replenishmentPackageProduct, replenishmentPackageProduct?.product_package?.expected_product_quantity)}
                                        >
                                            {i18n.t("replenishment.confirm_stock_quantity")}
                                        </Button>

                                        <Button type="secondary" className="" onClick={() => setShowQuantityInput(true)}>
                                            {i18n.t("replenishment.not_confirm_stock_quantity")}
                                        </Button>
                                    </div>
                                </>
                            )}

                        </div>
                    </Transition.Child>
                </div>
            </Dialog>
        </Transition>
    )
}
